<script>
import aclModuleClient from '@/core/acl-module-client';


export default {
  name: 'AclGroupDetails',
  components: {
  },
  data: () => ({
    loading: true,
    group: {
      id: null,
      loading: true,
      data: {},
    },
    editingGroup: {
      loading: false,
      data: {
        title: '',
        description: '',
        active: false,
      },
    },
    group_roles: {
      loading: true,
      data: [],
      items: [],
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'title', label: 'Name', sortable: true },
        { key: 'app_name', label: 'Application', sortable: true },
        { key: 'category_name', label: 'Category', sortable: true },
        { key: 'actions', label: '' },
      ],
      filters: {
        page: 1,
        pageSize: 15,
        role: null,
        application: null,
        category: null,
      },
      sort: {
        sortBy: '',
        sortDesc: false,
      },
    },
    available_group_roles: {
      loading: true,
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'application', label: 'Application', sortable: true },
        { key: 'category', label: 'Category', sortable: true },
        { key: 'selected', label: 'Select' },
      ],
      total: 0,
      paginatedItems: new Map(),
      filters: {
        page: 1,
        pageSize: 15,
        name: '',
        application: null,
        category: null,
      },
      sort: {
        sortBy: '',
        sortDesc: false,
        keyMap: {
          id: 'id',
          name: 'title',
          application: 'app_id',
          category: 'category_id',
        },
      },
      applyFilterBtn: {
        loading: false,
      },
      saveBtn: {
        loading: false,
      },
      confirmRolesBtn: {
        loading: false,
      },
    },
    group_okta_groups: {
      loading: true,
      items: [],
      fields: [
        { key: 'okta_group_id', label: 'OKTA Id' },
        { key: 'region_code', label: 'Region' },
        { key: 'more', label: 'More' },
      ],
    },
    roles: {
      loading: true,
      items: [],
    },
    apps: {
      loading: true,
      items: [],
    },
    categories: {
      loading: true,
      items: [],
    },
    new_role: {
      loading: false,
      id: null,
    },
    new_okta_group: {
      loading: false,
      id: null,
    },
  }),
  beforeMount() {
    this.group.id = this.$route.params.id;

    return Promise.all([
      this.fetchCategories(),
      this.fetchApps(),
      this.fetchGroup(),
      this.fetchGroupRoles(),
      this.fetchGroupOKTAGroups(),
      this.fetchAvailableGroupRoles(),
      this.changeAvailableRolesPage(this.available_group_roles.filters.page),
    ]).then(() => {
      this.handleApplyGroupRolesFilters();
    });
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', event => {
      if (event.componentId === 'modal-edit-group') {
        this.editingGroup.data.title = this.group.data.title;
        this.editingGroup.data.description = this.group.data.description;
        this.editingGroup.data.active = this.group.data.active === 1;
      }
    });
  },
  computed: {
    isAllSelected: {
      get() {
        return this.available_group_roles.paginatedItems.get(this.available_group_roles.filters.page)
          .every(role => role.selected);
      },
      set(value) {
        return value;
      },
    },
    availableRolesApplicationFiltersOptions() {
      return [
        { text: 'All applications', value: null },
        ...this.apps.items.map(app => ({ text: app.name, value: app.id })),
      ];
    },
    availableRolesCategoriesFiltersOptions() {
      return [
        { text: 'All categories', value: null },
        ...this.categories.items.map(app => ({ text: app.name, value: app.id })),
      ];
    },
    groupRolesFiltersOptions() {
      const uniqueRoles = new Map();
      this.group_roles.data.forEach(role => {
        uniqueRoles.set(`${role.title}-${role.id}`,
          {
            text: role.title,
            value: role.id,
          });
      });

      return [
        { text: 'All roles', value: null },
        ...Array.from(uniqueRoles.values()),
      ];
    },
    groupRolesApplicationFiltersOptions() {
      const uniqueApps = new Map();
      this.apps.items.forEach(app => {
        uniqueApps.set(`${app.name}-${app.id}`,
          {
            text: app.name,
            value: app.id,
          });
      });

      return [
        { text: 'All applications', value: null },
        ...Array.from(uniqueApps.values()),
      ];
    },
    groupRolesCategoryFiltersOptions() {
      const uniqueCategories = new Map();
      this.categories.items.forEach(cat => {
        uniqueCategories.set(`${cat.name}-${cat.id}`,
          {
            text: cat.name,
            value: cat.id,
          });
      });

      return [
        { text: 'All categories', value: null },
        ...Array.from(uniqueCategories.values()),
      ];
    },
  },
  methods: {
    fetchGroup() {
      return aclModuleClient.get(`v1/groups/${this.group.id}`)
        .then(response => {
          this.group.data = response.data;
          this.editingGroup.data.title = response.data.title;
          this.editingGroup.data.description = response.data.description;
          this.editingGroup.data.active = response.data.active === 1;
        })
        .catch(e => {
          this.$noty.error('Error loading groups');
          console.error(e);
        })
        .finally(() => {
          this.group.loading = false;
        });
    },
    fetchGroupRoles() {
      this.group_roles.loading = true;
      return aclModuleClient.get(`v1/groups/${this.group.id}/roles`)
        .then(response => {
          this.group_roles.data = response.data;
          this.group_roles.items = response.data;
          this.group_roles.total = response.data.length;
          return this.handleApplyGroupRolesFilters();
        })
        .catch(e => {
          this.$noty.error('Error loading group roles');
          console.error(e);
        })
        .finally(() => {
          this.group_roles.loading = false;
        });
    },
    fetchGroupOKTAGroups() {
      this.group_okta_groups.loading = true;
      return aclModuleClient.get(`v1/groups/${this.group.id}/okta`)
        .then(response => {
          this.group_okta_groups.items = response.data;
        })
        .catch(e => {
          this.$noty.error('Error loading okta groups');
          console.error(e);
        })
        .finally(() => {
          this.group_okta_groups.loading = false;
        });
    },
    fetchAvailableGroupRoles() {
      const { filters, sort } = this.available_group_roles;
      let search = [];
      if (filters.name) search.push(`title:${filters.name}`);
      if (filters.application) search.push(`app_id:${filters.application}`);
      if (filters.category) search.push(`category_id:${filters.category}`);
      search = search.join(',');

      const sortKey = sort.keyMap[sort.sortBy] || sort.sortBy;
      const params = {
        page: filters.page,
        limit: filters.pageSize,
        sort: sortKey ? `${sortKey}:${sort.sortDesc ? 'desc' : 'asc'}` : null,
      };
      if (search !== '') {
        params.search = search;
      }

      return aclModuleClient
        .get('v1/roles', { params })
        .then(({ data: { data: items, total } }) => {
          const formattedItems = items.map(role => ({
            id: role.id,
            name: role.title,
            application: role.app_name,
            category: role.category_name,
            selected: false,
          }));
          this.available_group_roles.paginatedItems.set(
            this.available_group_roles.filters.page,
            formattedItems,
          );
          this.available_group_roles.total = total;
        });
    },
    fetchCategories() {
      return aclModuleClient
        .get('v1/categories', { params: { limit: 100 } })
        .then(response => { this.categories.items = response.data.data; })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading categories');
        }).finally(() => {
          this.categories.loading = false;
        });
    },
    fetchApps() {
      return aclModuleClient.get('v1/apps')
        .then(response => {
          this.apps.items = response.data.data;
        })
        .catch(e => {
          this.$noty.error('Error loading apps');
          console.error(e);
        })
        .finally(() => {
          this.apps.loading = false;
        });
    },
    getAppById(appId) {
      return this.apps.items.find(app => app.id === appId);
    },
    getCategoryFromId(categoryId) {
      return this.categories.items.find(cat => cat.id === categoryId);
    },
    changeAvailableRolesPage(newPage) {
      this.available_group_roles.filters.page = newPage;
      this.available_group_roles.loading = true;

      const promise = this.available_group_roles.paginatedItems.has(this.available_group_roles.filters.page)
        ? Promise.resolve()
        : this.fetchAvailableGroupRoles();

      return promise
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading available roles');
        })
        .finally(() => {
          this.available_group_roles.loading = false;
        });
    },
    handleSaveRoles() {
      this.available_group_roles.saveBtn.loading = true;

      const selectedRoles = [];
      this.available_group_roles.paginatedItems
        .forEach(roles => {
          roles.forEach(role => {
            if (role.selected && !this.group_roles.data.some(item => item.id === role.id)) {
              selectedRoles.push(role.id);
            }
            role.selected = false;
          });
        });

      if (!selectedRoles.length) {
        this.$noty.info('You must select at least one role to assign to this user.');
        this.available_group_roles.saveBtn.loading = false;
        return;
      }

      aclModuleClient
        .post(`v1/groups/${this.group.id}/roles`, { role_ids: selectedRoles })
        .then(() => {
          this.$noty.success('Roles added to group');
          this.fetchGroupRoles();
        })
        .catch(error => {
          this.$noty.error(`Error adding roles to group:<br/>${error.message}`);
          console.error(error);
        })
        .finally(() => {
          this.available_group_roles.saveBtn.loading = false;
        });
    },
    handleApplyAvailableRolesFilters() {
      this.available_group_roles.paginatedItems.clear();
      this.available_group_roles.filters.page = 1;
      this.changeAvailableRolesPage(this.available_group_roles.filters.page);
    },
    handleApplyGroupRolesFilters() {
      const roleMatchesFn = (fltr, val) => (!(fltr !== null && fltr !== val));
      this.group_roles.items = this.group_roles.data.reduce((items, groupRole) => {
        if (
          roleMatchesFn(this.group_roles.filters.role, groupRole.id)
          && roleMatchesFn(this.group_roles.filters.application, groupRole.app_id)
          && roleMatchesFn(this.group_roles.filters.category, groupRole.category_id)
        ) {
          items = [
            ...items,
            {
              id: groupRole.id,
              app_id: groupRole.app_id,
              app_name: this.getAppById(groupRole.app_id)?.name,
              title: groupRole.title,
              category_id: groupRole.category_id,
              category_name: this.getCategoryFromId(groupRole.category_id)?.name,
              loading: false,
            },
          ];
        }
        return items;
      }, [ ]);
    },
    onGroupRolesSortChanged({ sortBy, sortDesc }) {
      this.group_roles.sort.sortBy = sortBy;
      this.group_roles.sort.sortDesc = sortDesc;
    },
    onAvailableGroupRolesSortChanged({ sortBy, sortDesc }) {
      this.available_group_roles.loading = true;
      this.available_group_roles.sort.sortBy = sortBy;
      this.available_group_roles.sort.sortDesc = sortDesc;
      this.fetchAvailableGroupRoles()
        .then(() => {
          this.changeAvailableRolesPage(this.available_group_roles.filters.page);
          this.available_group_roles.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading available roles');
          this.available_group_roles.loading = false;
        });
    },
    async addOKTAGroups() {
      try {
        this.new_okta_group.loading = true;
        await aclModuleClient.patch(`v1/groups/${this.group.id}/okta/${this.new_okta_group.id}`);
        this.$noty.success('OKTA Group add!');
        this.new_okta_group.id = null;
      } catch (error) {
        this.$noty.error('Failed to add OKTA Group');
        console.error(error);
      } finally {
        this.new_okta_group.loading = false;
        this.fetchGroupOKTAGroups();
      }
    },
    async deleteOKTAGroups(oktaGroupId, regionId) {
      const value = await this.$bvModal.msgBoxConfirm(`Delete okta group ${oktaGroupId}`, { title: 'Confirmation' });

      if (!value) {
        return;
      }

      try {
        await aclModuleClient.delete(`v1/groups/${this.group.id}/okta/${oktaGroupId}/regions/${regionId}`);

        this.$noty.success('OKTA Group removed!');
      } catch (error) {
        this.$noty.error('Failed to remove OKTA Group');
        console.error(error);
      } finally {
        this.fetchGroupOKTAGroups();
      }
    },
    confirmUpdateGroup() {
      if (!this.editingGroup.data.title || this.editingGroup.data.title === '') {
        this.$noty.error('Group title cannot not be empty');
        return Promise.resolve();
      }

      this.editingGroup.loading = true;
      const modalHideEventHandler = e => { e.preventDefault(); };
      this.$root.$on('bv::modal::hide', modalHideEventHandler);

      const { active, ...data } = this.editingGroup.data;

      return Promise.all([
        aclModuleClient.put(`v1/groups/${this.group.id}`, data),
        aclModuleClient.patch(`v1/groups/${this.group.id}/status`, { active: active ? 1 : 0 }),
      ])
        .then(() => this.fetchGroup())
        .then(() => {
          this.$noty.success('Group updated successfully');
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
          this.$refs['modal-edit-group'].hide();
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error trying to update group');
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
        })
        .finally(() => {
          setTimeout(() => {
            this.editingGroup.loading = false;
          }, 500);
        });
    },
    modalAddOktaGroupHidden() {
      this.new_okta_group.id = null;
    },
    goToEditRoles() {
      this.$router.push(`/acl/groups/${this.group.id}/assigned-roles`);
    },
    selectAllRoles(isAllSelected) {
      this.available_group_roles.paginatedItems.get(this.available_group_roles.filters.page).forEach(role => {
        role.selected = isAllSelected;
      });
    },
    async deleteGroupRole(role) {
      const value = await this.$bvModal.msgBoxConfirm(`Delete role  ${role.title}`, { title: 'Confirmation' });

      if (!value) {
        return Promise.resolve();
      }

      return aclModuleClient
        .delete(`v1/groups/${this.group.id}/roles/${role.id}`)
        .then(() => {
          this.$noty.success('Role removed from group');
          this.fetchGroupRoles();
        })
        .catch(error => {
          this.$noty.error(`Error removing role from group:<br/>${error.message}`);
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <b-container class="p-4">
    <b-row>
      <b-col class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <h4 class="mb-0 mr-2">ACL Group details - {{group.data.title}}</h4>
          <b-badge variant="success" v-if="group.data.active">Active</b-badge>
          <b-badge variant="danger" v-else>Inactive</b-badge>
        </div>

        <div>
          <b-button
            variant="primary"
            class="mr-2"
            :disabled="group.loading"
            v-b-modal.modal-edit-group>
            <b-spinner v-if="group.loading" class="align-middle" small />
            <feather v-else type="edit" />
            Edit Group
          </b-button>
          <b-button
            variant="primary"
            class="mr-2"
            title="Assign this group to legacy (LC's) roles."
            @click="goToEditRoles()"
            v-b-tooltip:hover>
            <feather type="file"></feather>
            Assigned Roles
          </b-button>
          <b-button variant="primary" v-b-modal.modal-add-okta>
            <feather type="sun"></feather>
            OKTA Groups
          </b-button>
        </div>

      </b-col>
    </b-row>

    <b-row class="mb-4">
      <b-col>
        <small class="description-text">
          {{ group.data.description }}
        </small>
      </b-col>
    </b-row>

    <b-row cols="1" cols-xl="2">
      <!-- #region Group Roles -->
      <b-col class="mb-4">
        <b-card no-body>
          <b-card-header class="d-flex justify-content-between">
            <b-card-title class="m-0 align-content-center">
              Group Roles
              <sup
                v-b-tooltip:hover
                title="These are the roles currently assigned to this group.">
                <feather type="help-circle"></feather>
              </sup>
            </b-card-title>

            <div>
              <b-button
                variant="outline-secondary"
                title="Show filters"
                v-b-tooltip:hover
                v-b-toggle.collapse-user-roles-filters>
                Filters
                <feather type="filter"></feather>
              </b-button>
            </div>
          </b-card-header>

          <b-card-body>
            <b-row>
              <b-col>
                <b-collapse id="collapse-user-roles-filters">
                  <b-card class="mb-3" bg-variant="light" no-body>
                    <b-card-body>
                      <b-card-title class="">
                        Filters
                      </b-card-title>

                      <b-form @submit.prevent>
                        <b-form-row>
                          <b-col>
                            <b-form-group id="arf-group-name" label="Role" label-for="arf-name">
                              <b-form-select
                                id="arf-application"
                                v-model="group_roles.filters.role"
                                :options="groupRolesFiltersOptions" />
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group id="arf-group-application" label="Application" label-for="arf-application">
                              <b-form-select
                                id="arf-application"
                                v-model="group_roles.filters.application"
                                :options="groupRolesApplicationFiltersOptions" />
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group id="arf-group-application" label="Application" label-for="arf-application">
                              <b-form-select
                                id="arf-application"
                                v-model="group_roles.filters.category"
                                :options="groupRolesCategoryFiltersOptions" />
                            </b-form-group>
                          </b-col>
                        </b-form-row>

                        <b-form-row>
                          <b-col class="d-flex justify-content-end">
                            <b-button
                              prevent
                              type="submit"
                              variant="primary"
                              title="Apply filters"
                              v-b-tooltip:hover
                              :disabled="group_roles.loading"
                              @click.prevent="handleApplyGroupRolesFilters">
                              Apply
                              <b-spinner v-if="group_roles.loading" class="align-middle ml-2" small/>
                              <feather v-else type="filter"></feather>
                            </b-button>
                          </b-col>
                        </b-form-row>

                      </b-form>
                    </b-card-body>
                  </b-card>
                </b-collapse>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-table
                  :fields="group_roles.fields"
                  :items="group_roles.items"
                  :busy="group_roles.loading"
                  sticky-header="800px"
                  show-empty
                  hover
                  bordered
                  striped
                  :sort-by.sync="group_roles.sort.sortBy"
                  :sort-desc.sync="group_roles.sort.sortDesc"
                  @sort-changed="onGroupRolesSortChanged">
                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle mr-2"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>

                  <template #empty>
                    <div class="text-center my-2">
                      <strong>No group roles to show</strong>
                    </div>
                  </template>

                  <template #cell(id)="{ item }">
                    <code>{{ item.id }}</code>
                  </template>

                  <template #cell(title)="{ item }">
                    <code>{{ item.title }}</code>
                  </template>

                  <template #cell(app_name)="{ item }">
                    <code>{{ item.app_name }}</code>
                  </template>

                  <template #cell(category_name)="{ item }">
                    <code>{{ item.category_name }}</code>
                  </template>

                  <template #cell(actions)="{ item }">
                    <b-badge variant="danger" role="button"
                            @click="deleteGroupRole(item)">
                      <feather type="x-square"/>
                    </b-badge>
                  </template>

                </b-table>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- #endregion -->

      <!-- #region Available Group Role -->
      <b-col>
        <b-card no-body>
          <b-card-header class="d-flex justify-content-between">
            <b-card-title class="m-0 align-content-center">
              Available Group Roles
              <sup
                v-b-tooltip:hover
                title="These are all the roles available that can be assigned to this group.">
                <feather type="help-circle"></feather>
              </sup>
            </b-card-title>

            <div>
              <b-button
                class="mr-4"
                variant="outline-secondary"
                title="Show filters"
                v-b-tooltip:hover
                v-b-toggle.collapse-available-roles-filters>
                Filters
                <feather type="filter"></feather>
              </b-button>

              <b-button
                variant="primary"
                v-b-tooltip:hover
                title="Assign selected roles to this group."
                :disabled="available_group_roles.saveBtn.loading"
                @click="handleSaveRoles">
                Save roles
                <b-spinner v-if="available_group_roles.saveBtn.loading" class="align-middle ml-2" small/>
                <feather v-else type="save"></feather>
              </b-button>
            </div>
          </b-card-header>

          <b-card-body>
            <b-row>
              <b-col>
                <b-collapse id="collapse-available-roles-filters">
                  <b-card class="mb-3" bg-variant="light" no-body>
                    <b-card-body>
                      <b-card-title class="">
                        Filters
                      </b-card-title>

                      <b-form @submit.prevent>
                        <b-form-row>
                          <b-col>
                            <b-form-group id="arf-group-name" label="Name" label-for="arf-name">
                              <b-form-input
                                id="arf-name"
                                placeholder="Enter role name"
                                v-model="available_group_roles.filters.name" />
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group id="arf-group-application" label="Application" label-for="arf-application">
                              <b-form-select
                                id="arf-application"
                                v-model="available_group_roles.filters.application"
                                :options="availableRolesApplicationFiltersOptions" />
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group id="arf-group-category" label="Category" label-for="arf-category">
                              <b-form-select
                                id="arf-category"
                                v-model="available_group_roles.filters.category"
                                :options="availableRolesCategoriesFiltersOptions" />
                            </b-form-group>
                          </b-col>

                        </b-form-row>

                        <b-form-row>
                          <b-col class="d-flex justify-content-end">
                            <b-button
                              prevent
                              type="submit"
                              variant="primary"
                              title="Apply filters"
                              v-b-tooltip:hover
                              :disabled="available_group_roles.loading"
                              @click.prevent="handleApplyAvailableRolesFilters">
                              Apply
                              <b-spinner v-if="available_group_roles.loading" class="align-middle ml-2" small/>
                              <feather v-else type="filter"></feather>
                            </b-button>
                          </b-col>
                        </b-form-row>

                      </b-form>
                    </b-card-body>
                  </b-card>
                </b-collapse>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-table
                  :fields="available_group_roles.fields"
                  :items="available_group_roles.paginatedItems.get(available_group_roles.filters.page)"
                  :busy="available_group_roles.loading"
                  @row-clicked="item => (item.selected = !item.selected)"
                  tbody-tr-class="pointerCursor"
                  show-empty
                  hover
                  bordered
                  striped
                  :sort-by.sync="available_group_roles.sort.sortBy"
                  :sort-desc.sync="available_group_roles.sort.sortDesc"
                  @sort-changed="onAvailableGroupRolesSortChanged">

                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle mr-2"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>

                  <template #head(selected)>
                    <div class="text-center">
                      <b-form-checkbox v-if="available_group_roles?.paginatedItems.has(available_group_roles?.filters.page)"
                        v-model="isAllSelected" @change="selectAllRoles" />
                    </div>
                  </template>

                  <template #empty>
                    <div class="text-center my-2">
                      <strong>No available group roles to show</strong>
                    </div>
                  </template>

                  <template #cell(id)="{ item }">
                    <code>{{ item.id }}</code>
                  </template>

                  <template #cell(name)="{ item }">
                    <code>{{ item.name }}</code>
                  </template>

                  <template #cell(application)="{ item }">
                    <code>{{ item.application }}</code>
                  </template>

                  <template #cell(category)="{ item }">
                    <code>{{ item.category }}</code>
                  </template>


                  <template #cell(selected)="{ item }" >
                    <div class="text-center m-0">
                      <b-form-checkbox
                        :id="`role_${item.id}`"
                        v-model="item.selected"/>
                    </div>
                  </template>

                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-pagination
                  class="my-0"
                  align="center"
                  :value="this.available_group_roles.filters.page"
                  :total-rows="available_group_roles.total"
                  :per-page="available_group_roles.filters.pageSize"
                  @change="changeAvailableRolesPage">
                </b-pagination>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- #endregion -->
    </b-row>

    <!-- #region Add OKTA groups Modal -->
    <b-modal size="lg" id="modal-add-okta" title="OKTA groups" @hidden="modalAddOktaGroupHidden">
      <b-row class="d-flex mb-2">
        <b-col cols="11">
          <b-form-group>
            <b-form-input v-model="new_okta_group.id" placeholder="Add OKTA Group Id" @keyup.enter="addOKTAGroups" />
          </b-form-group>
        </b-col>
        <b-col cols="1">
          <b-button variant="primary" class="float-bottom" :disabled="new_okta_group.loading" @click="addOKTAGroups">
            <b-spinner v-if="new_okta_group.loading" type="border" small></b-spinner>
            <feather v-else type="plus"></feather>
          </b-button>
        </b-col>
      </b-row>

      <b-row class="d-flex">
        <b-col cols="12" class="mt-3">
          <b-table :fields="group_okta_groups.fields" :items="group_okta_groups.items" :busy="group_okta_groups.loading"
                   outlined sticky-header="800px" striped empty-text="No okta groups found">
            <template #cell(more)="data">
              <b-button variant="danger" @click="deleteOKTAGroups(data.item.okta_group_id, data.item.region_id)">
                <feather type="x-square"/>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <template #modal-footer="{ hide }">
        <b-button variant="secondary" @click="hide('cancel')">Cancel</b-button>
      </template>
    </b-modal>
    <!-- #endregion -->

    <!-- #region Edit group -->
    <b-modal id="modal-edit-group" ref="modal-edit-group" :title="`Edit group - ${group.data.title}`">
      <b-container class="modal-body p-0" fluid>
        <b-form-row>
          <b-col>
            <b-form-group label="Title">
              <b-form-input
                placeholder="Title"
                v-model="editingGroup.data.title"
                required />
            </b-form-group>

            <b-form-group label="Description">
              <b-form-textarea
                id="textarea"
                placeholder="Description"
                rows="3"
                v-model="editingGroup.data.description" />
            </b-form-group>

            <div class="ml-2 pl-1 d-flex align-items-center">
              <b class="mr-2">Active</b>
              <b-form-checkbox
                v-model="editingGroup.data.active"
                switch />
            </div>
          </b-col>
        </b-form-row>
      </b-container>

      <template #modal-footer="{ hide }">
        <b-button variant="secondary" :disabled="editingGroup.loading" @click="hide('cancel')">
          Cancel
        </b-button>
        <b-button variant="primary" :disabled="editingGroup.loading" @click="confirmUpdateGroup">
          Update
        </b-button>
      </template>
    </b-modal>
    <!-- #endregion -->

  </b-container>
</template>

<style scoped>
.description-text {
  display:inline-block;
  overflow-x: hidden;
  overflow-y: auto;
  text-overflow: ellipsis;
  max-width: 50%;
  max-height: 100px;
}
</style>
